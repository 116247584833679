.dashboard__topitem__icon {
    font-size: 60px;
    color: #D32F2F;
}

.dashboard__topitem__title {
  color: #2A2A2A;
  // font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  margin: 0;
}

.dashboard__tittle__container {
    display: flex;
    align-items: center;
}

.slide-container {
    height: 315px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    margin: 10px 0 10px 0;
    background-color: white;
}

.slide-container img {
    max-height: 182px;
    display: block;
    margin: 0 auto;
  }

.slide-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    // .slick-slider__caption {
    //     bottom: 25px;
    //     transition: bottom 0.5s;
    // }
  }



  .slick-prev {
      left: -15px !important;
  }

  .slick-next {
    right: -15px !important;
  }

  .slick-slider {
    margin-bottom: 24px;
    width: 100%;
  }

  .dashboard__agent-img {
    width: 150px !important;
    height: 150px !important;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
  }

.top__item__number {
  color: #2A2A2A !important;
  // font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin: 0;
}

.card__title {
  margin-bottom: 0px;
}

.item-active {
  background-color: #D32F2F !important;
 
  span, h4, p{
    color: white !important;
  };
}

.dashboard__item  {
  text-align: center;
}

.agent-item {
  text-align: center;
  bottom: 30px !important;

  p {
    text-align: center;
  }
}

.agent-address {
  opacity: 0.6;
  max-height: unset !important;
}

.agent-name {
  height: 30px !important;
}