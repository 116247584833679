@import "~video-react/styles/scss/video-react";

.product-gallery {
  width: 100%;
}
.product-gallery:fullscreen {
  .product-gallery__current-img {
    height: 100vh * 0.85;
    .video_wrapper > div:nth-child(1) {
      height: 100vh * 0.85 !important;
      width: 100vw !important;
      max-width: 100vw !important;
  }
  }

}
.product-gallery__current-img {
  height: 440px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;

  // @include themify($themes) {
  //   border: 1px solid themed('colorBorder');
  // }

  img {
    height: 100%;
    // width: auto;
  }
  video {
    outline: none;
    height: 440px;
  }
}
.video_wrapper > div:nth-child(1) {
    width: 800px !important;
    height: 440px !important;
}
.full-screen-toggle {
  display: flex;
  align-items: center;
  padding-right: 10px;
  .full-screen-icon {
      width: 15px;
      height: 15px;
  }
}
.full-screen-toggle:hover {
  .full-screen-icon {
    width: 17px;
    height: 17px;
  }
  cursor: pointer;
}
.next_fullscreen_button {
  z-index: 10000;
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
  border: 5px solid #ffffff;
  border-top: 0px;
  border-left: 0px;
  background: none;
  transform: rotate(-45deg);
  right: 15px;
  border-radius: 5px;
  top: (100vh*0.85)/2;
  opacity: 0.25;
}
.next_fullscreen_button:hover {
  opacity: 1;
  border-color:#70bbfd;
}
.pre_fullscreen_button {
  z-index: 10000;
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
  border: 5px solid #ffffff;
  border-top: 0px;
  border-left: 0px;
  background: none;
  transform: rotate(135deg);
  left: 15px;
  border-radius: 5px;
  top: (100vh*0.85)/2;
  opacity: 0.25;
}
.pre_fullscreen_button:hover {
  opacity: 1;
  border-color:#70bbfd;
}

.product_gallery__gallery {
  display: flex;
  justify-content: center;
}

.product_gallery__main_wrapper {
  display: contents;
  img {
    width: auto;
  }
}

.product-gallery__img-certified-logo {
  width: 70px;
  position: absolute;
  z-index: 2;
  right: 40px;
  top: 40px
}

.product-gallery__img-preview {
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
  padding: 0;
  background: transparent;

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
  }

  &:last-child {
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }

  img {
    height: 80px;
  }

  video {
    outline: 0px;
    height: 80px;
  }
}
.gallery-video-wrapper {
  outline: 0px;
}
.product-gallery__next-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
  border-top: 0px;
  border-left: 0px;
  background: none;
  border-bottom: 5px solid #70bbfd;
  border-right: 5px solid #70bbfd;
  transform: rotate(-45deg);
}
.product-gallery__pre-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
  border-top: 0px;
  border-left: 0px;
  background: none;
  border-bottom: 5px solid #70bbfd;
  border-right: 5px solid #70bbfd;
  transform: rotate(135deg);
}
@media screen and (max-width: 1199px) {

  .product-gallery {
    margin: auto;
  }
}
