@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.slick-slider {
  width: calc(100% + 15px);
  margin-bottom: 24px;

  .slick-slide {
    overflow: hidden;
    @include directify($directions) {
      #{directed('padding-right')}: 15px;
    }
    padding-right: 15px;
    position: relative;

    img {
      max-width: 100%;
      max-height: 180px;
     
    }
  }

  .slick-list {
    width: 100%;
  }

  .slick-arrow {
    height: 100%;
    width: 100px;
    z-index: 1;

    &:before {
      color: #dddddd;
      font-weight: 500;
      position: absolute;
      top: calc(50% - 15px);
      font-size: 30px;
      line-height: 30px;
      font-family: inherit;
      width: 30px;
      transition: all 0.3s;
    }

    &:hover {
      color: $color-additional;
    }

    @media screen and (max-width: 750px){
      display: none !important;
    }
  }

  @mixin next($dir) {
    @if($dir == 'ltr') {
      right: 0;
    }
    @else {
      right: -15px;
    };
  }

  .slick-arrow.slick-next {
    left: auto;
    @include directify($directions) {
      @include next(#{directed('direction')});
    }
    background: linear-gradient(90deg, transparent, #F3F7FD);

    &:before {
      content: '\203A';
      right: 5px;
    }
  }

  @mixin prev($dir) {
    @if($dir == 'ltr') {
      left: -15px;
    }
    @else {
      left: 0;
    };
  }

  .slick-arrow.slick-prev {
    background: linear-gradient(-90deg, transparent, #F3F7FD);
    @include directify($directions) {
      @include prev(#{directed('direction')});
    }

    &:before {
      content: '\2039';
      left: -10px;
      color: #dddddd;
    }
  }

  .slick-dots {

    li {
      width: 10px;

      button {
        padding: 0;
        width: 10px;

        &:before {
          font-size: 10px;
          width: 10px;
          height: 10px;
          color: #d8d8d8;
          opacity: 1;
          transition: all 0.3s;
        }
      }

      &.slick-active {

        button:before {
          color: #F3F7FD;
        }
      }

      &:hover {

        button:before {
          color: $color-additional;
        }
      }
    }
  }

  &.slick-slider--single {

    .slick-arrow {
      background: transparent;
    }
  }
  .property_carousel_item_header {
    height: 50%;
    position: relative;
    display: flex;
    img {
      width: auto;
      max-height: 100%;
    }
    &.img-certified-logo {
      position: absolute;
      width: 25%;
      height: auto;
      z-index: 2;
      right: 5px;
      top: 5px
    }
    &.video-thumb {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
    }
  }
  .slick-slider__caption__img-certified-logo {
    width: 20%;
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 20px
  }

  .slick-slider__caption {
    
    height: 135px;
    overflow: hidden;
  
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin: 5px 0;
      padding: 5px 5px;
    }
  }

  .slick-slider__caption-title {
    font-size: 16px;
    font-weight: 500;
    color: $color-red-theme;
    line-height: 20px;
    height: 30px;
  }

  .slick-slider__caption-description {
    margin: 0;
    max-height: 20px;
    color: #2A2A2A;
    // font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;

  }

  .slick-slider__caption-address {
    margin: 0;
    line-height: 16px;
    color: #2A2A2A;
    // font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    opacity: 0.6;
  }
}
