.properties__status-select {
    height: 38px;
    min-height: 32px;
}

.react-select__value-container--has-value {
    height: 38px !important;
    padding: 0 10px !important;
    margin: 0 !important;
}

.form__form-group-field {
    padding-left: 0px !important;
}

.properties__filer-border {
    border: 1px solid hsl(0,0%,80%) !important;
    border-radius: 5px;
}

.property__agent-img {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
}

.agent__infor {
    padding: 10px 0;
}

.property__detail__button__group {
    text-align: center;
    margin-top: 15px;
}

.property__verification-btn {

    position: relative;
    bottom: 5px;
    float: right;
}

.react-select {
    height: 30px;
}

.react-select__control {
    height: 30px;
}

.react-select__value-container{
    height: 30px;
}

.react-select__single-value {
    margin-top: 5px;
}
.property__item{
    padding-right: 15px;
    outline: none;

}

.properties {
    background-color: #F3F7FD;
}

.property__detail__address {
    color: #2A2A2A !important;
    // font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin: 10px 0 15px 0;
}

.property__detail__price {
    color: #2A2A2A;
    // font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
}

.property__detail__pricenumber{
    color: red !important;
    // font-family: Montserrat;
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    text-align: left;
}

.key__infor_name {
    color: rgb(126, 126, 126);;
    // font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
}

.key__infor_value {
    color: #2A2A2A;
    // font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    display: block;
    // text-transform: capitalize;
}

.key__infor_value:first-letter {
    text-transform: uppercase;
}

.key__infor__row {
    padding: 5px;
    margin: 10px 0;
}

.facility {
    justify-content: center
}

.key__info__icon_warpper {
    padding: 15px;
}

.key__infor__icon {
    font-size: 30px;
    display: block;
    margin: 0 auto;
    color: #D32F2F;
}

.key__infor__name {
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    text-align: center;
}

.property__filter__input {
    height: 38px !important;
    padding: 2px 10px !important;
    border-color: #cccccc !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none !important;
    color: black
}

.property__filter__calender {
    height: 38px;
    padding: 2px 0;
    border-color: hsl(0,0%,80%) !important;;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 44px;
    svg {
        margin-top: 7px;
        margin-left: 5px;
    }
}

.property__detail__leftcolumn {

    padding-right: 40px;

    @media (max-width: 768px) {
        padding: 0;
    }
}

.property__reject {
    margin-right: 10px
  }

.property__reject_with_icon {
    margin-right: 10px;
    height: 32px;
    padding-left: 36px;
    border: 1px solid #000;
    background: #ff9900 url(https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-icon-png-image_6315974.png) no-repeat 10px center;
    cursor: pointer;
}

.property__tem-title {
    height: 30px !important;
    line-height: 23px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.referTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .item {
    display: inline-flex;
    align-items: center;
    flex-basis: 50%;
    margin-bottom: 10px;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    span:first-child {
      width: 11px;
      height: 11px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  .tenantmodal {
      max-width: 500px;
  }

  .pieChart {
    max-width: 260px;
    margin: 30px auto 50px;
  }

  .schedule_item {
    color: black;
    // font-family: Montserrat;
    font-size: 15px;
    line-height: 17px;
    text-align: left;
    font-weight: bold
}