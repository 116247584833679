.ambassador__infor {
    padding: 10px 0;
}
.ambassador__list-button {
    margin-left: 15px;
}
.ambassador__appointment-header {
    color: #2A2A2A;
    background-color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}
.ambassador__appointment-visited {
    color: green
}

.ambassador__appointment-time {
    margin-left: 10px;
    font-weight: bold;
}
.ambassador__appointment-upcomming{
    color: green;
}

.ambassador__appointment-cancel{
    color: red;
}

.form__form-group-img {
    margin: 0 auto;
}

.form__form-group-label {
    font-weight: bold;
}

.property__ambassador-img {
    width: 220px;
    height: 220px;
    margin-top: 30px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
}

.ambassador__takeover-btn {
    text-align: center;
}